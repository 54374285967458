<template>
  <section id="concentracao-instrucoes">
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          no-actions
        >
          <div class="text-center">
            <h5>
              Pressione o botão <code>F11</code> para responder o teste em tela
              cheia.
            </h5>
            <b-card-title class="mt-2">
              Orientação do Jogo de Atenção
            </b-card-title>
            <b-card-title class="mt-2">
              Como jogar?
            </b-card-title>
          </div>
          <b-card-text class="mt-2 text-center">
            Memorize as duas imagens destacadas, e pressione em todas as iguais
            a elas nas linhas a seguir. Caso errar, pressione mais uma vez.
          </b-card-text>

          <b-card-text class="mt-2 text-center">
            Verifique as imagens como se estivesse lendo um texto. Ou seja, da
            esquerda para a direita, uma linha após a outra.
          </b-card-text>

          <b-card-text class="mt-2 text-center">
            Antes de começar, você vai praticar nessa página. Depois,
            <b>você terá tempo limite de {{ formato }} para jogar</b>.
          </b-card-text>

          <!-- <b-card-text class="mt-2">
            A figura igual ao modelo deve ser selecionada todas as vezes que ela
            aparecer. Quando tiver terminado o teste, clique em
            <b>Finalizar</b>.
          </b-card-text>

          <b-card-text>
            <b>Você terá {{ formato }} para realizar o teste</b>.
          </b-card-text>

          <b-card-text class="mt-2">
            Como esta etapa é um treino, uma mensagem será exibida quando todas
            as figuras corretas forem selecionadas.
          </b-card-text> -->

          <b-col
            v-if="desenhaMatriz"
            md="8"
            class="containerMain mx-auto mt-2"
          >
            <div
              :key="'Referencia-' + String(Math.floor(Math.random() * 10))"
              class="containerReferencia"
            >
              <div id="box">
                <div
                  v-for="(coluna, index) in referencia"
                  :id="'referencia-' + String(index + 1)"
                  :key="'referencia-' + String(index + 1)"
                  class="imagemReferencia"
                  v-html="imagemSvg(coluna.codigo)"
                />
              </div>
            </div>

            <div
              :key="'Matriz-' + String(Math.floor(Math.random() * 10))"
              class="tabela"
            >
              <div
                v-for="(linha, index) in matriz.slice(0, 2)"
                :id="'linha-' + String(index + 1)"
                :key="'linha-' + String(index + 1)"
                class="linha"
              >
                <div
                  v-for="coluna in linha"
                  :id="String(coluna.codigo)"
                  :key="coluna.codigo"
                  :class="coluna.clicou ? 'colunaClicked' : 'colunaUnclicked'"
                  @click="confereClique($event, coluna.codigo, coluna.clicou)"
                  v-html="imagemSvg(coluna.codigoImage)"
                />
              </div>
            </div>
            <div class="mt-2">
              <div class="text-center mt-2">
                <b-button
                  v-if="finalizou == false"
                  variant="primary"
                  @click="checkGame()"
                >
                  Checar Treino
                </b-button>
              </div>

              <b-alert
                variant="success"
                :show="finalizou"
              >
                <h4 class="alert-heading">
                  Parabéns
                </h4>
                <div class="alert-body">
                  <p>
                    Você conseguiu encontrar todas as alternativas corretas.
                  </p>
                  <p />
                  <p>
                    Assim que estiver pronto para jogar clique no botão abaixo.
                  </p>
                  <br>
                  <p>
                    <span
                      style="color: #FFA500; text-decoration: underline;"
                    >Lembre-se</span>
                    que ali você não irá receber nenhum alerta indicando que já
                    acertou, e deverá clicar em 'Finalizar' para concluir a
                    partida.
                  </p>
                  <br>
                </div>
              </b-alert>
            </div>
            <div class="text-center mt-2">
              <b-button
                v-if="finalizou"
                variant="primary"
                @click="limparTeste()"
              >
                Ir para o jogo da Atenção
              </b-button>
            </div>
          </b-col>
        </b-card-actions>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import {
  BRow,
  BCol,
  BCardText,
  BCard,
  BCardHeader,
  BCardBody,
  BAlert,
  BCardTitle,
  BButton
} from "bootstrap-vue"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { mapState } from "vuex"

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BButton,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      desenhaMatriz: false,
      dados: null,
      imagens: [],
      referencia: [],
      matriz: [],
      tempo: 0,
      alternativasCorretas: 0, // Quantidade de opções corretas na linha 1 e 2
      pontos: 0, // Quantidade de pontos para comparar com o alternativasCorretas
      finalizou: false,
      minutos: 0,
      segundos: 0,
      timer: "0000",
      formato: ""
    }
  },
  beforeMount() {
    this.buscarTesteDeConcentracao()
  },
  mounted() {
    this.$refs["loadingCard"].showLoading = true
  },
  methods: {
    limparTeste() {
      // Antes de enviar o usuário para outra tela é preciso limpar o teste a fim de redefinir o css
      for (let i = 0; i < this.matriz.length; i++) {
        for (let j = 0; j < this.matriz[i].length; j++) {
          if (this.matriz[i][j].clicou) {
            this.matriz[i][j].clicou = !this.matriz[i][j].clicou
          }
        }
      }
      this.$router.push({ name: "concentracao" })
    },
    prepararTesteDeConcentracao() {
      this.$refs["loadingCard"].showLoading = false
      let matrizLimpa = []
      this.dados.matriz.forEach(x => {
        // console.log("matriz Item: ", x.matrizItem)
        matrizLimpa.push(x.matrizItem)
      })
      this.matriz = matrizLimpa
      this.imagens = this.dados.imagens
      // Remove width e height do primeiro nó de cada svg a fim de manter a imagem sem altura e largura
      let regex = new RegExp(/(width="|height=").+?" /g)
      for (let i = 0; i < this.imagens.length; i++) {
        let svgSeparado = this.imagens[i].svg.split(">")
        svgSeparado[0] = svgSeparado[0].replace(regex, "", 1)
        let svgJuntado = svgSeparado.join(">")
        this.imagens[i].svg = svgJuntado
      }
      this.referencia = this.dados.referencia
      this.$store.commit("listaReferencia", this.referencia)
      sessionStorage.setItem("ref", JSON.stringify(this.referencia))
      this.tempo = this.dados.tempo
      this.quantificaAlternativasCorretas()
      this.convertTempo()
      this.desenhaMatriz = true

      // console.log("Imagem 1: ", this.imagens[0].svg)
    },

    convertTempo() {
      this.timer = this.tempo

      this.minutos = parseInt(this.timer / 60, 10)
      this.segundos = parseInt(this.timer % 60, 10)

      this.formato =
        (this.minutos < 10 ? "0" + this.minutos : this.minutos) +
        ":" +
        (this.segundos < 10 ? "0" + this.segundos : this.segundos)

      return this.formato
    },

    async buscarTesteDeConcentracao() {
      const resp = await useJwt
        .buscarTesteDeConcentracao({})
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemErrorBuscarConcentracao(
            "danger",
            "top-center",
            catchError
          )
        })

      if (resp.sucesso) {
        // console.log("Dados: ", resp.dados)
        this.dados = resp.dados
        this.prepararTesteDeConcentracao()
      }
    },
    confereClique(evento, codigoClicado, clicou) {
      for (let i = 0; i < this.matriz.length; i++) {
        const found = this.matriz[i].find(e => e.codigo == codigoClicado)
        if (found) {
          found.clicou = !found.clicou
          if (found.clicou) {
            if (
              found.codigoImage === this.referencia[0].codigo ||
              found.codigoImage === this.referencia[1].codigo
            ) {
              this.pontos += 1
            } else {
              this.pontos -= 1
            }
          } else {
            if (
              found.codigoImage === this.referencia[0].codigo ||
              found.codigoImage === this.referencia[1].codigo
            ) {
              this.pontos -= 1
            } else {
              this.pontos += 1
            }
          }
          // console.log("y: ", found)
          // console.log("pontos: ", this.pontos)
          this.alternativasCorretas === this.pontos
            ? (this.finalizou = true)
            : (this.finalizou = false)
          break
        }
      }
    },
    imagemSvg(codigo) {
      // console.log("Coluna: ", coluna)
      // console.log("Código: ", codigo)
      // console.log("imagens: ", this.imagens)
      const imagem = this.imagens.find(y => y.codigo === codigo)
      // console.log("imagem: ", imagem)
      // console.log("Imagem svg", imagem.svg)
      return imagem.svg
    },
    quantificaAlternativasCorretas() {
      // Essa função vai definir a quantidade de alternativas corretas na 1ª e 2ª linha
      let corretos = 0
      for (let i = 0; i < 2; i++) {
        // console.log(this.matriz[i])
        for (let x = 0; x < this.matriz[i].length; x++) {
          if (
            this.matriz[i][x].codigoImage === this.referencia[0].codigo ||
            this.matriz[i][x].codigoImage === this.referencia[1].codigo
          ) {
            corretos += 1
          }
        }
      }
      // console.log("Alternativas corretas: ", corretos)
      this.alternativasCorretas = corretos
    },
    checkGame() {
      if (this.finalizou != true) {
        this.MensagemCheckGame("warning", "top-center")
      }
    },
    MensagemErrorBuscarConcentracao(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro ao carregar o teste.",
            icon: "AlertTriangleIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemCheckGame(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Desculpe, você ainda não conseguiu encontrar todas as alternativas corretas.",
            icon: "CoffeeIcon",
            variant,
            text: "Não desanime, continue tentando!"
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>
<style scoped>
.containerMain {
  background-color: white;
  padding: 0.1%;
  border-radius: 15px;
}
.tabela {
  width: 100%;
  display: table;
}
.linha {
  display: table-row;
}
.colunaClicked {
  /* width: 50px;
  max-width: 30px; */
  padding: 1%;
  margin: 1%;
  display: table-cell;
  opacity: 1;
  border-radius: 15px;
  background-color: rgb(205, 205, 205);
}
.colunaUnclicked {
  /* width: 50px;
  max-width: 30px; */
  padding: 1%;
  display: table-cell;
  opacity: 1;
}
.containerReferencia {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imagemReferencia {
  padding: 5%;
  width: 50%;
}

#box {
  border-radius: 15px;
  width: 20%;
  border: 1px gray solid;
  box-shadow: 0px 5px 10px #969696;
  -webkit-box-shadow: 0px 5px 10px #969696;
  display: flex;
  flex-direction: row;
}

svg {
  overflow: hidden;
  vertical-align: middle;
  width: 20px;
}
</style>
